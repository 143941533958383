import { mask } from "./mask.helper";

export function phoneMask(phoneNumber: string) {
  if (!phoneNumber) return "Não informado";

  if (phoneNumber.length > 13) {
    return mask(phoneNumber.replace("+55", ""), "(##) # #### ####");
  }

  return mask(phoneNumber.replace("+55", ""), "(##) #### ####");
}

export default phoneMask;
